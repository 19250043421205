import React from "react";
import { Modal, View, TextInput, TouchableOpacity, Text } from "react-native";
import { SectionModelStyle as styles } from "@src/styles/SectionModel.style";

type NewSectionModalProps = {
  isModalVisible: boolean;
  closeModal: () => void;
  newSectionTitle: string;
  setNewSectionTitle: (title: string) => () => void;
  sectionTitleError: string | null;
  handleSubmitNewSection: () => void;
};

const NewSectionModal: React.FC<NewSectionModalProps> = ({
  isModalVisible,
  closeModal,
  newSectionTitle,
  setNewSectionTitle,
  sectionTitleError,
  handleSubmitNewSection,
}) => {
  return (
    <Modal
      visible={isModalVisible}
      animationType="slide"
      transparent={true}
      onRequestClose={closeModal}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Enter Section Details</Text>
          <View style={styles.modalInnerContainer}>
            <TextInput
              style={styles.textInput}
              placeholder="Enter section title..."
              value={newSectionTitle}
              onChangeText={(text) => setNewSectionTitle(text)()}
            />
            {sectionTitleError && (
              <Text style={styles.errorText}>{sectionTitleError}</Text>
            )}
            <View style={styles.btnContainer}>
              <TouchableOpacity
                style={styles.addButton}
                onPress={handleSubmitNewSection}
              >
                <Text style={styles.buttonText}>Add</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.cancelButton}
                onPress={closeModal}
              >
                <Text style={styles.buttonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default NewSectionModal;
