// @styles/Review.style.ts
import { StyleSheet } from 'react-native';

export const SectionModelStyle = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      modalContent: {
        backgroundColor: "white",
        width: "80%",
        alignItems: "center",
        borderRadius: 10,
      },
      modalInnerContainer: {
        padding: 20,
        alignItems: "center",
        width: '100%',
      },
      modalTitle: {
        color: "#fff",
        fontWeight: "bold",
        fontSize: 18,
        marginBottom: 10,
        backgroundColor: '#296a6a',
        padding: 20,
        width: '100%',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
      textInput: {
        width: "100%",
        borderBottomWidth: 1,
        marginBottom: 20,
        padding: 10,
        fontSize: 16,
        height: 40,
        margin: 12,
        borderRadius: 8,
        borderWidth: 1,
      },
      errorText: {
        color: "red",
        fontSize: 14,
        marginTop: -15,
        marginBottom: 10,
        alignSelf: "flex-start",
        paddingLeft: 5,
      },
      btnContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 10,
      },
      addButton: {
        backgroundColor: "#296a6a",
        paddingVertical: 10,
        paddingHorizontal: 50,
        borderRadius: 5,
      },
      cancelButton: {
        backgroundColor: "#333333",
        paddingVertical: 10,
        paddingHorizontal: 50,
        borderRadius: 5,
      },
      buttonText: {
        color: "#fff",
        fontSize: 16,
        fontWeight: "bold",
      },
});
