// @services/stubs/StubAuthenticationClient.ts
import { AuthenticationClient } from '@services/clients/AuthenticationClient';
import {
    CreateAccountRequest,
    LoginRequest,
    JwtState,
    SubscriptionStatus, JwtResponse,
} from '@model/clients/AuthenticationApi';
import { TaskEither, right } from 'fp-ts/lib/TaskEither';
import { AuthenticationError } from '@model/clients/AuthenticationApi';
import { delayTaskEither } from '@utils/delayTaskEither'; // Assuming this helper exists

export class StubAuthenticationClient implements AuthenticationClient {
    createAccount(
        request: CreateAccountRequest
    ): TaskEither<AuthenticationError, void> {
        console.log('StubAuthenticationClient.createAccount called', request);

        // Use delayTaskEither for the delay and success case
        return delayTaskEither(100, right(undefined));
    }

    login(request: LoginRequest): TaskEither<AuthenticationError, SubscriptionStatus> {
        console.log('StubAuthenticationClient.login called', request);

        // Hardcoded to return 'FreeTrialMode' subscription status
        return delayTaskEither(100, right('FreeTrialMode' as SubscriptionStatus));
    }

    refreshJwtToken(): TaskEither<AuthenticationError, JwtResponse> {
        console.log('StubAuthenticationClient.refreshJwtToken called');

        // Use delayTaskEither for the delay and a successful token refresh
        return delayTaskEither(100, right({ status: 'authorized' , subscription_status: 'FreeTrialMode' as SubscriptionStatus} as JwtResponse));
    }

    logout(): TaskEither<AuthenticationError, void> {
        console.log('StubAuthenticationClient.logout called');

        // Use delayTaskEither for the delay and successful logout
        return delayTaskEither(100, right(undefined));
    }
}
