// @components/writing/review/SectionCard.tsx
import React from "react";
import { Card, Text, TouchableRipple } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import { Section as SectionType } from "@model/documents/core";
import Markdown from "react-native-markdown-display";
import * as IO from "fp-ts/lib/IO"; // Import Task utilities
import { appStyles } from "@src/styles/App.style";

type SectionCardProps = {
  section: SectionType;
  active: boolean;
  onPressIo: IO.IO<void>; // Task-based onPress
};

const SectionCard: React.FC<SectionCardProps> = ({
  section,
  active,
  onPressIo,
}) => {

  const InnerCard = (
    <Card
      style={[
        styles.card,
        active && styles.activeCard,
        { backgroundColor: "#296a6a" },
      ]}
    >
      <Card.Title title={section.metadata.name} titleStyle={styles.title} />
      <Card.Content>
        {section.section_contents ? (
          <Text style={{ color: "#fff" }}>
            <Card style={{ backgroundColor: "#FFF" }}>
              <Markdown style={markdownStyles}>{section.section_contents}</Markdown>
            </Card>
          </Text>
        ) : (
          <Card style={{ backgroundColor: "#FFF" }}>
            <Text style={styles.placeholderText}>
              This section hasn't been started, tap or click this text, then the
              button that appears to get started.
            </Text>
          </Card>
        )}
      </Card.Content>
    </Card>
  );

  return active ? (
    // Render the card directly when active (no TouchableRipple)
    <View>{InnerCard}</View>
  ) : (
    // Wrap the card in TouchableRipple when inactive
    <TouchableRipple
      onPress={onPressIo}
      accessibilityRole="button"
      accessibilityLabel={`${section.metadata.name}`}
      accessibilityState={{ expanded: active }}
    >
      {InnerCard}
    </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  card: {
    marginVertical: 8,
  },
  activeCard: {
    borderColor: "#296a6a",
    borderWidth: 2,
  },
  markdown: {
    color: "#fff",
  },
  title: {
    color: "#ffffff",
    fontWeight: 'bold',
  },
  placeholderText: {
    color: "#000",
    padding: 16
  },
});

const markdownStyles = StyleSheet.create({
  body: {
    fontSize: 16,
    lineHeight: 24, // Comfortable line height for readability
    color: 'black',
    padding: 16,
    letterSpacing: 0.4, // Slight letter spacing for better readability
  },
  heading1: {
    fontSize: 28,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 12,
  },
  heading2: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 18,
    marginBottom: 10,
  },
  heading3: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 16,
    marginBottom: 8,
  },
  paragraph: {
    marginBottom: 12, // Add spacing between paragraphs
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  blockquote: {
    // backgroundColor: colors.accent + '20', // Lightened accent color for quote background
    // borderLeftColor: colors.accent,
    borderLeftWidth: 4,
    paddingLeft: 12,
    marginVertical: 8,
  },
  codeBlock: {
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 4,
    fontFamily: 'monospace',
    fontSize: 14,
    marginVertical: 8,
  },
});

export default React.memo(SectionCard, (prevProps, nextProps) => {
  return (
    prevProps.active === nextProps.active &&
    prevProps.section === nextProps.section
  );
});
