// @styles/Review.style.ts
import { StyleSheet } from "react-native";

export const WritingStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#fff",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  subheader: {
    fontSize: 18,
    marginBottom: 16,
  },
  subsubheader: {
    fontSize: 12,
    marginBottom: 16,
  },
  documentContainer: {
    flex: 1,
    marginBottom: 16,
  },
  section: {
    marginBottom: 16,
  },
  actionButton: {
    paddingVertical: 8.5,
    paddingHorizontal: 25,
    flexDirection: "row",
    gap: 2,
    backgroundColor: "#296a6a",
    borderRadius: 5,
    alignItems: "center",
  },
  icon: {
    marginRight: 10,
  },
  actionButtonText: {
    color: "#fff",
    fontWeight: 'bold'
  },
  sectionHeader: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 5,
  },
  buttonContainerSecond: {
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 5,
  },
  button: {
    backgroundColor: "#296a6a",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
});
