// @pages/account/Login.page.tsx

import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AuthenticationRoutes, AppRoutes, WorkspaceRoutes, PaymentRoutes } from '@navigation/RouteEnums';
import { AuthenticationStackParamList, AppStackParamList } from '@navigation/NavigationRouteParameters';
import { useAuthenticationClient } from '@contexts/AuthenticationClient.context';
import { useJwt } from '@contexts/Jwt.context';
import SexyLoginComponent from '@src/components/authentication/Login';
import { Email, Password, SubscriptionStatus } from '@model/clients/AuthenticationApi';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/TaskEither';
import * as TE from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';
import * as IO from 'fp-ts/lib/IO'; // Import IO utilities
import { AuthenticationError } from '@model/clients/AuthenticationApi';
import { useIoBasedLoadingSetters } from '@contexts/Loading.context'; // use io setters
import { handleAuthenticationErrorIo } from '@utils/errorHandlers/errorHandlers'; // use io error
import { delay } from 'fp-ts/Task';

type LoginProps = {
    navigation: StackNavigationProp<AuthenticationStackParamList, typeof AuthenticationRoutes.LOGIN>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

// IO for navigating to document selection
const navigateToHomeIo = (
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>
): IO.IO<void> => () =>
    appNavigator.navigate(AppRoutes.WORKSPACE, { screen: WorkspaceRoutes.HOME });

// IO for navigating to payment setup
const navigateToPaymentSetupIo = (
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>,
    subscriptionStatus: SubscriptionStatus
): IO.IO<void> => () =>
    appNavigator.navigate(AppRoutes.PAYMENT, {
        screen: PaymentRoutes.CHOOSE_SUBSCRIPTION,
        params: { subscription_status: subscriptionStatus },
    });

// IO-based task for handling login success and triggering success state
const handleLoginSuccessIo = (
    triggerSuccessState: IO.IO<void>,
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>,
    subscriptionStatus: SubscriptionStatus
): IO.IO<void> => pipe(
    triggerSuccessState,
    IO.chain(() =>
        subscriptionStatus === 'FreeTrialMode' || subscriptionStatus === 'Paid'
            ? navigateToHomeIo(appNavigator)
            : navigateToPaymentSetupIo(appNavigator, subscriptionStatus)
    )
);

// IO for handling account creation navigation
const navigateToCreateAccountIo = (
    navigation: StackNavigationProp<AuthenticationStackParamList, typeof AuthenticationRoutes.LOGIN>
): IO.IO<void> => () => navigation.navigate(AuthenticationRoutes.CREATE_ACCOUNT);

const LoginPage: React.FC<LoginProps> = ({ navigation, appNavigator }) => {
    const { authenticationClient } = useAuthenticationClient();
    const { isTokenExpiring, refreshToken, subscriptionStatus } = useJwt();
    const { triggerLoadingState, triggerSuccessState, triggerErrorState } = useIoBasedLoadingSetters(); // use io setters

    useEffect(() => {
        void refreshToken(); // Ignores errors in a suspicious way
    }, [refreshToken]);

    useEffect(() => {
        if (!isTokenExpiring && (subscriptionStatus === 'FreeTrialMode' || subscriptionStatus === 'Paid')) {
            void navigateToHomeIo(appNavigator)();
        }
    }, [isTokenExpiring, appNavigator]);

    function retryWithDelay<A>(
        task: TE.TaskEither<AuthenticationError, A>,
        delayMs: number
    ): TE.TaskEither<AuthenticationError, A> {
        return pipe(
            task,
            TE.orElse((error) =>
                error.type === 'ServerError'
                    ? pipe(
                        TE.rightTask(delay(delayMs)(T.fromIO(() => undefined))),
                        TE.flatMap(() => task)
                    )
                    : TE.left(error) // Return the original error if not server error
            )
        );
    }

    const handleLogin = (email: Email, password: Password): T.Task<void> =>
        pipe(
            T.fromIO(triggerLoadingState),
            T.chain(() =>
                pipe(
                    retryWithDelay(authenticationClient.login({ email, password }), 500),
                    TE.orElse((error: AuthenticationError) =>
                        error.type === 'ServerError'
                            ? authenticationClient.login({ email, password })
                            : TE.left(error)
                    ),
                    fold(
                        (error: AuthenticationError) =>
                            T.fromIO(handleAuthenticationErrorIo(error, triggerErrorState)),
                        (subscriptionStatus) =>
                            T.fromIO(handleLoginSuccessIo(triggerSuccessState, appNavigator, subscriptionStatus))
                    )
                )
            )
        );

    return (
        <>
            <SexyLoginComponent
                onLogin={handleLogin}
                navigateToCreateAccount={navigateToCreateAccountIo(navigation)}
            />
        </>
    );
};

export default LoginPage;
