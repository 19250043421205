import React from "react";
import {
  View,
  TextInput,
  TouchableOpacity,
  Text,
  StyleSheet,
} from "react-native";
import { useIoState } from "@utils/useIoState"; // Assuming this is the custom hook for IO state
import * as T from "fp-ts/lib/Task";

interface DemographicSurveyFormProps {
  onSubmit: (userTitle: string, userIndustry: string) => T.Task<void>; // Task-based function
}

const DemographicSurveyForm: React.FC<DemographicSurveyFormProps> = ({
  onSubmit,
}) => {
  const [userTitle, setUserTitle] = useIoState("");
  const [userIndustry, setUserIndustry] = useIoState("");

  const handleSubmit = (): void => {
    void onSubmit(userTitle, userIndustry)();
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <Text style={styles.title}>Demographic Survey</Text>

        <TextInput
          style={styles.input}
          value={userTitle}
          onChangeText={(text) => setUserTitle(text)()} // IO-based setter
          placeholder="Enter your title"
        />

        <TextInput
          style={styles.input}
          value={userIndustry}
          onChangeText={(text) => setUserIndustry(text)()} // IO-based setter
          placeholder="Enter your industry"
        />


        <TouchableOpacity
          style={[styles.button]}
          onPress={handleSubmit}
        >
          <Text style={styles.buttonText}>
            {"Submit"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    padding: 50,
    width: '80%',
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 8,
  },
  title: {
    fontSize: 24,
    color: "#000",
    width: '100%',
    fontWeight: "bold",
    marginBottom: 20,
  },
  input: {
    width: '100%',
    marginBottom: 10,
    height: 40,
    color: "#000",
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  button: {
    backgroundColor: "#296a6a",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonDisabled: {
    backgroundColor: "#aaa",
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  error: {
    color: "red",
    marginBottom: 10,
  },
});

export default DemographicSurveyForm;
