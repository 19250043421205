// styles/OuroborosSexyTheme.style.ts
import { Dimensions, StyleSheet } from 'react-native';

export const sexyStyles = StyleSheet.create({
    background: {
        flex: 1,
        flexDirection: "row",
        height: Dimensions.get('window').height,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        marginTop: 16,
        backgroundColor: '#053A3A',
    },
    card: {
        width: '85%',
        maxWidth: 550,
        padding: 20,
        borderRadius: 10,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    container: {
        flex: 1,
        marginVertical: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        marginBottom: 16,
    },
    inputPayment: {
        borderColor: '#ccc',
        borderWidth: 1,
        backgroundColor: '#fff',
        borderRadius: 5,
        paddingHorizontal: 10,
        paddingVertical: 8,
        shadowColor: 'rgba(0, 0, 0, 0.03)',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        elevation: 3,
        marginBottom: 5,
    },    
    inputPaymentCVC: {
        borderColor: '#ccc',
        borderWidth: 1,
        backgroundColor: '#fff',
        borderRadius: 5,
        paddingHorizontal: 5,
        paddingVertical: 8,
        shadowColor: 'rgba(0, 0, 0, 0.03)',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        elevation: 3,
        marginBottom: 5,
    },
    textButton: {
        backgroundColor: '#d3d3d3',
        marginTop: 8,
    },
    title: {
        textAlign: 'center',
        marginBottom: 16,
        fontSize: 24,
        fontWeight: 'bold',
    },
    loading: {
        flex: 1,
        shadowColor:'transparent',
        paddingTop: 20,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        // marginLeft: -20, // Half the width of the spinner
        // marginTop: -20,  // Half the height of the spinner
    },
    textColor: {
        color: '#000'
    }
})